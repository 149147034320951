* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  font-family: "Noto Serif KR", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
